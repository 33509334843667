import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We often hear people asking about retention — what’s a good retention metric for bots?`}</p>
    <p>{`We’ve processed nearly 2 billion messages in the past 11 months. It took 10 months to process the first billion and we’re on pace to process the second billion in about six weeks!`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125956/0_bzqt0XkcDf7BXTIw.png)
    </figure>
We took a look at the retention data across bots on the Dashbot platform.
    <p>{`We used the same definition Localytics used when reporting on `}<a parentName="p" {...{
        "href": "http://info.localytics.com/blog/mobile-apps-whats-a-good-retention-rate"
      }}>{`Mobile App Retention`}</a>{`. For 30 day retention, we counted users who come back at any point within the next 30 days after their initial visit. We followed the same approach for 60 day (users who come back between days 31–60) and 90 day (users who come back between days 61 and 90).`}</p>
    <p>{`Interestingly enough, chatbot 30 day retention is on par with mobile apps. Localytics saw a 36% 30 day retention rate.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125956/0_NnfSlNR1DgsRRfje.png)
    </figure>
We can dive deeper into categories on Facebook and see retention for specific
use cases. The most popular based on one month retention rates are Games,
Social, Local, Health &amp; Fitness, and Shopping.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125955/0_XfmfVF5EBv0YNc3v.png)
    </figure>
Social Faux Pas — we often hear one shouldn’t talk about sex, religion, or
politics in social settings…. well how about in chat bots?
    <p>{`While politics has fairly low engagement, both religion and dating chatbots have decent 30 day retention, which drop off significantly afterwards. Perhaps the users found what they were looking for…`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125955/0_7-kiwYIqDG01BheV.png)
    </figure>
Speaking of sex, what about drugs and rock ‘n roll? While the overall number of
the drug related chat bots and music ones are a bit low to be statistically
significant, below are the early signals.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125954/7308b-1awfcdmexi0kmmf5vv9g63w.png)
    </figure>
[Dashbot](http://www.dashbot.io/) is a bot analytics platform that enables
developers and brands to increase engagement, acquisition, and monetization
through actionable data and tools.
    <p>{`In addition to traditional analytics like engagement and retention, we provide bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      